












































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
export default {
  name: 'uOttawa1321Prelab6Q1',
  components: {
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'To make sure that there is no air coming into the reaction',
          value: 'noAir',
        },
        {
          text: 'To make sure that there is no water coming into the reaction as organic compounds are not soluble in water',
          value: 'noWater',
        },
        {
          text: 'To make sure the solvent does not evaporate since diethyl ether has a boiling point of only 35°C',
          value: 'noEvaporation',
        },
        {
          text: 'To make sure that there is no water coming into the reaction and thus preventing the acid-base reaction with the Grignard reagent',
          value: 'noReaction',
        },
      ],
      optionsFr: [
        {
          text: "Pour faire ceratin qu'il n'y a pas d'air qui rentre dans la réaction",
          value: 'noAir',
        },
        {
          text: "Pour être certain qu'il n'y a pas d'eau qui rentre dans la réaction puisque les composés organiques ne sont pas solubles dans l'eau",
          value: 'noWater',
        },
        {
          text: "Pour être certain que le solvant ne s'évapore pas puisque l'éther éthylique a un point d'ébullition de seulement 35°C",
          value: 'noEvaporation',
        },
        {
          text: "Pour être certain qu'il n'y a pas d'eau qui rentre dans la réaction et ainsi pour prévenir la reaction acidobasique avec le réactif de Grignard",
          value: 'noReaction',
        },
      ],
    };
  },
};
